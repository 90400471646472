// @flow

import React from 'react'

import { eventMeta } from '@raywhite/helpers-utils/lib/helpers/link'

type Props = {
  onClick: () => void,
}

const appraisalMeta = eventMeta({
  category: 'Appraisal',
  action: 'Open',
  label: 'Site',
})

const AppraisalButton = ({
  onClick,
}: Props) => {
  // Note: see optimize account for experiments related to these variations
  const tabletButton = (
    <a
      onClick={onClick}
      className="home_header_cta btn brand_btn no_arrow tablet"
      {...appraisalMeta}
    >
      Book an Appraisal
    </a>
  )
  const mobileButton = (
    <a
      onClick={onClick}
      className="home_header_cta btn light mobile"
      {...appraisalMeta}
    >
      Book a Free Appraisal
    </a>
  )

  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      {tabletButton}
      {mobileButton}
    </div>
  )
}

export default AppraisalButton

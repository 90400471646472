// @flow
import React, { Component } from 'react'
import classNames from 'classnames'
import { stringify } from 'query-string'

import { eventMeta } from '@raywhite/helpers-utils/lib/helpers/link'

import siteFocusConfig from '../../siteFocusConfig'
import HeaderBar from './HeaderBar.jsx'
import SlideMenu from './SlideMenu.jsx'
import HeaderMenu from './HeaderMenu.jsx'

import type { MenuItem } from '../../types'

const appraisalMeta = eventMeta({
  category: 'Appraisal',
  action: 'Open',
  label: 'Site',
})

const HeadlineBanner = ({
  countryCode = 'AU',
  organisationId,
}: {
  // $FlowFixMe
  countryCode: string,
  organisationId: number,
}) => {
  const lead = countryCode === 'AU'
    ? 'Sell with Confidence'
    : 'Latest Market Statistics'
  const cta = countryCode === 'AU'
    ? 'Read More'
    : 'Read Now'
  const href = countryCode === 'AU'
    ? 'https://www.raywhite.com/ray-white-now/'
    : 'https://raywhite.co.nz/ray-white-now/'

  const query = stringify({
    orgId: organisationId,
    utm_source: 'RW Office Site',
    utm_medium: 'referral',
    utm_content: 'Top Banner',
    utm_campaign: `${lead} / ${cta}`,
  })

  /* eslint-disable react/jsx-no-target-blank */
  return (
    <div className="beyondthebricks">
      {lead}
      <br className="hide_bravo" />
      {' '}
      <a
        className="btn brand_btn"
        href={`${href}?${query}`}
        onClick={(event) => {
          if (countryCode === 'AU') return

          // NZ wants a stupid popup
          event.preventDefault()
          window.open(href, 'rw-now', 'resizable=yes, scrollbars=yes')
        }}
        target="_blank"
        rel="noopener"
        data-ev-on="click"
        data-ev-category="Ray White Now"
        data-ev-action="Click"
        data-ev-label={cta}
      >
        <span
          className="anchor mini"
          data-ev-on="click"
          data-ev-category="Ray White Now"
          data-ev-action="Click"
          data-ev-label={cta}
        >
          {cta}
        </span>
      </a>
    </div>
  )
  /* eslint-enable react/jsx-no-target-blank */
}

/**
 * Walks through the tree adding handlers to show submenus where applicable.
 */
function initSubmenuHandlers(showSubmenu, hideSubmenu, depth, ...args) {
  return args.map((items, prefix) => items.map<MenuItem>((item, index): MenuItem => {
    const id = `${prefix}:${depth}_${index}`
    if (!(item.children && item.children.length)) {
      return { id, ...item }
    }

    return {
      id,
      ...item,
      showSubmenu: () => showSubmenu(id),
      hideSubmenu: () => hideSubmenu(id),
      children: initSubmenuHandlers(showSubmenu, hideSubmenu, depth + 1, item.children)[0],
    }
  }))
}

type State = {
  showSubs: {},
  menuVisible: boolean,
  primaryMenu: Array<MenuItem>,
  secondaryMenu: Array<MenuItem>,
};

type Props = {
  homeLinkLabel: string,
  headerClass?: string,
  menuLoaded: boolean,
  primaryMenu: Array<MenuItem>,
  secondaryMenu: Array<MenuItem>,
  menuTitle: string,
  toggleEnquiryModal: () => void,
  showAppraisalModal: () => void,
  siteFocus: string,
  lockBody: () => void,
  unlockBody: () => void,
  countryCode?: string,
  orgSubTypeCode: string,
  organisationIds: Array<number>,
  primaryOrg: {
    id: number,
  },
};

export class AppHeader extends Component<Props, State> {
  props: Props

  state: State

  hidingMenu: ?TimeoutID

  static defaultProps = {
    menuLoaded: false,
    countryCode: undefined,
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      showSubs: {},
      menuVisible: false,
      ...this.processMenus(props.primaryMenu, props.secondaryMenu),
    }
  }

  componentDidUpdate(prevProps: Props) {
    // Reprocess menus if menu structure props have changed
    const { primaryMenu: lastPrimary, secondaryMenu: lastSecondary } = prevProps
    const { primaryMenu, secondaryMenu } = this.props
    if (primaryMenu !== lastPrimary || secondaryMenu !== lastSecondary) {
      // NOTE(@elyobo): Disabled because it's safe here
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(this.processMenus(primaryMenu, secondaryMenu))
    }
  }

  componentWillUnmount() {
    if (this.hidingMenu && clearTimeout) clearTimeout(this.hidingMenu)
  }

  processMenus(_primaryMenu: Array<MenuItem>, _secondaryMenu: Array<MenuItem>) {
    const [primaryMenu, secondaryMenu] = initSubmenuHandlers(
      this.showSubmenu, this.hideSubmenu, 0, _primaryMenu, _secondaryMenu
    )
    return { primaryMenu, secondaryMenu }
  }

  showMenu = () => {
    this.props.lockBody()
    this.setState({ menuVisible: true })
  }

  hideMenu = () => {
    this.props.unlockBody()
    this.setState({ menuVisible: false }, () => {
      // No need for later update if no open submenus
      if (!Object.keys(this.state.showSubs).length) return

      // Delay hiding submenus until after menu is hidden, 20ms
      this.hidingMenu = setTimeout && setTimeout(() => {
        this.setState({ showSubs: {} })
      }, 20)
    })
  }

  showSubmenu = (id: string) => {
    this.props.lockBody()
    this.setState(state => ({
      menuVisible: true,
      showSubs: { ...state.showSubs, [id]: true },
    }))
  }

  hideSubmenu = (id: string) => {
    this.setState(state => ({
      showSubs: { ...state.showSubs, [id]: false },
    }))
  }

  isSubmenuActive = (id: string) => (
    id
      ? this.state.showSubs[id]
      : !!Object.values(this.state.showSubs).filter(x => x).length
  )

  handleContactUs = () => {
    this.props.toggleEnquiryModal()
    this.hideMenu()
  }

  render() {
    const {
      headerClass,
      homeLinkLabel,
      menuLoaded,
      menuTitle,
      countryCode,
      // organisationIds, not currently needed
      siteFocus,
      orgSubTypeCode,
      primaryOrg,
    } = this.props

    const { primaryMenu, secondaryMenu } = this.state
    const showBanner = orgSubTypeCode !== 'RWC'
    const { showAppraisalCTA = false } = siteFocusConfig[siteFocus] || {}

    return (
      <header
        className={classNames(
          'main_header',
          headerClass,
          showBanner ? 'with_banner' : 'without_banner',
        )}
      >
        <HeaderBar
          showMenu={this.showMenu}
          logoLabel={homeLinkLabel}
        >
          <HeaderMenu
            loaded={menuLoaded}
            primary={primaryMenu}
            hasSecondary={!!secondaryMenu.length}
            showMenu={this.showMenu}
            showSubmenu={this.showSubmenu}
          >
            {showAppraisalCTA && headerClass === 'home' && (
              <a
                onClick={this.props.showAppraisalModal}
                className="home_header_cta btn brand_btn no_arrow desktop"
                {...appraisalMeta}
              >
                Book a Free Appraisal
              </a>
            )}
          </HeaderMenu>
        </HeaderBar>
        {showBanner && (
          <HeadlineBanner
            countryCode={countryCode}
            organisationId={primaryOrg.id}
          />
        )}
        <SlideMenu
          menuTitle={menuTitle}
          visible={this.state.menuVisible}
          hideMenu={this.hideMenu}
          primary={primaryMenu}
          secondary={secondaryMenu}
          isSubmenuActive={this.isSubmenuActive}
          loaded={menuLoaded}
          onContactUs={this.handleContactUs}
          showSubs={this.state.showSubs}
        />
      </header>
    )
  }
}

export default AppHeader

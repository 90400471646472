// @flow

import React, { Component } from 'react'
import { Link } from 'react-router'
import type { Image } from '@raywhite/data-client/lib/modules/listings'

import { getCdnImage, renderLink } from '../../utils/media/renderers'
import Modal from './Modal.jsx'
import { defaultSearchImage } from '../../constants'
import HomePageSearchContainer from '../container/HomePageSearchContainer.jsx'
import AppraisalButton from './AppraisalButton.jsx'

/* eslint-disable max-len */
const DEFAULTS = {
  AU: {
    residential: {
      heading: "We don't sell houses. We sell homes.",
      subHeading: 'Knowledge. Skill. Experience. It’s how our agents maximise the value of your property. And it’s how we’ve sold more properties in Australasia than any other real estate group.',
      videoUrl: 'https://www.youtube.com/watch?v=oY3CTnNRi7U',
    },
    commercial: {
      heading: 'Providing the finest quality commercial real estate services to the market',
      subHeading: 'As part of the Ray White Group, the largest real estate group in Australasia, we have the strength and scope of an international real estate group with the local knowledge needed to deliver premium results.',
      videoUrl: undefined,
    },
  },
  NZ: {
    residential: {
      heading: 'Ray White know how to get more for your property',
      subHeading: 'Knowledge. Skill. Experience. It’s how our agents maximise the value of your property. And it’s how we’ve sold more properties in Australasia than any other real estate group.',
      videoUrl: undefined,
    },
    commercial: {
      heading: 'Providing the finest quality commercial real estate services to the market',
      subHeading: 'As part of the Ray White Group, the largest real estate group in Australasia, we have the strength and scope of an international real estate group with the local knowledge needed to deliver premium results.',
      videoUrl: undefined,
    },
  }
}
/* eslint-enable max-len */

type State = {
  showingVideo: boolean,
};

type Props = {
  countryCode: string,
  heading?: string,
  subheading?: string,
  superscript: string,
  image: false | Image,
  videoUrl?: string,
  linkUrl?: string,
  linkTitle?: string,
  linkProps?: {},
  organisationIds: Array<number>,
  theme: 'commercial' | 'residential',
  showAppraisalModal: () => void,
  showAppraisalCTA: boolean,
  _window: ?Object,
};

export default class HomePageHeader extends Component<Props, State> {
  state = { showingVideo: false }

  toggleShowingVideo = () => {
    this.setState(state => ({ showingVideo: !state.showingVideo }))
  }

  render() {
    const {
      countryCode,
      superscript,
      heading,
      subheading,
      image,
      linkUrl,
      linkTitle,
      linkProps,
      videoUrl: givenVideoUrl,
      organisationIds,
      theme,
      showAppraisalModal,
      showAppraisalCTA,
      _window,
    } = this.props

    const imageSrc = image
      && image.url && (
      `${getCdnImage(image.url)}${image.width > 1280 ? '?width=1280' : ''}`
    )

    const style = {
      backgroundImage: imageSrc ? `url(${imageSrc})` : `url(${defaultSearchImage})`,
    }

    const defaults = DEFAULTS[countryCode || 'AU'][theme]
    const videoUrl = givenVideoUrl || defaults.videoUrl

    return (
      <div
        className="home_header"
        style={style}
      >
        <div className="tbl">
          <div className="home_header_content tbc middle">
            <div className="inner">
              <h2 className="mini">{superscript}</h2>
              <h1 className="">
                {heading || defaults.heading}
              </h1>
              <HomePageSearchContainer
                theme={theme}
                organisationIds={organisationIds}
              />
              <p className="foxtrot regular_font">
                {subheading || defaults.subHeading}
              </p>
              {!!videoUrl && (
                <div className="home_header_play">
                  <span
                    className="play_button"
                    onClick={this.toggleShowingVideo}
                  />
                </div>
              )}
              {showAppraisalCTA && (
                <AppraisalButton
                  onClick={showAppraisalModal}
                  _window={_window}
                />
              )}
            </div>
          </div>
        </div>
        {!!(linkTitle && linkUrl) && (
          <div className="home_featured_property inner_lg">
            <Link to={linkUrl} {...linkProps}>{linkTitle}</Link>
          </div>
        )}
        {this.state.showingVideo && (
          <Modal
            full
            className="gallery_modal"
            handleClose={this.toggleShowingVideo}
          >
            <div className="gallery_modal_slide_wrap_outer">
              <div className="gallery_modal_slide">
                <div className="gallery_modal_slide_img">
                  {renderLink(videoUrl, undefined, { autoplay: 1 })}
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
    )
  }
}

// @flow

import React from 'react'
import { Link } from 'react-router'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'
import { stringify } from 'query-string'
import linkify from 'linkifyjs/html'
import stdurl from 'url'

import type { Listing, ClearingSaleItem } from '@raywhite/data-client/lib/modules/listings'

import {
  parseListingMedia,
  parseTours,
  linkTypeLabel,
  CRAPPY,
  FLOORPLAN,
  IMAGE,
  VIDEO,
  VIRTUAL_TOUR,
} from '@raywhite/media-utils/lib/media/media'
import { eventMeta } from '@raywhite/helpers-utils/lib/helpers/link'
import { stripMarkup, semantify, isSimilarSubstring } from '@raywhite/helpers-utils/lib/helpers/sanitization'
import { excerptify } from '@raywhite/helpers-utils/lib/helpers/string'
import {
  mayShowEnquiryForm,
  mayShowContractRequestForm,
  mayShowVideo,
  mayRequestInspection,
  getOfficeLicenceCode,
  mayShowStatementOfInformation,
} from '@raywhite/helpers-utils/lib/helpers/rules'
import {
  createPageTitle,
  getStructuredData,
  getListingUrl,
  getTypeSlug,
  getDisclaimers,
  isSellable,
} from '@raywhite/data-utils/lib/data/listing/listings'
import {
  stringifyAddress,
  describeParking,
  createPriceString,
} from '@raywhite/data-utils/lib/data/listing/formatting'
import { officeUrl } from '@raywhite/data-utils/lib/data/member/office'
import { labelLink } from '../../utils/beforeYouBid'
import tenappUrl from '../../utils/tenappUrl'
import snugUrl from '../../utils/snugUrl'
import LoanMarketWidget from './LoanMarketWidget.jsx'

import ZoomControl from './Mapbox/ZoomControl.jsx'
import ThreeDeeControl from './Mapbox/ThreeDeeControl.jsx'
import BuildingShapeLayer from './Mapbox/BuildingShapeLayer.jsx'
import BGImageMarker from './Mapbox/BGImageMarker.jsx'
import SingletonMap from './Mapbox/SingletonMap.jsx'
import ListingContacts from './ListingContacts.jsx'
import Modal from './Modal.jsx'
import ModalInner from './ModalInner.jsx'
import ListingMetadata from './ListingMetadata.jsx'
import ListingClearingMetadata from './ListingClearingMetadata.jsx'
import InvestmentMetadata from './InvestmentMetadata.jsx'
import BrokerCard from './BrokerCard.jsx'
import JsonLD from './JsonLD.jsx'
import TwitterSummaryCard from './TwitterSummaryCard.jsx'
import OpenGraph from './OpenGraph.jsx'
import ListingExtraFeatures from './ListingExtraFeatures.jsx'
import OneFormLink from './OneFormLink.jsx'
import TpsLink from './TpsLink.jsx'
import EventBox from './EventBox.jsx'
import ListingDetail from './ListingDetail.jsx'
import Price from './Price.jsx'
import ListingDescriptor from './ListingDescriptor.jsx'
import ShareLinks from './ShareLinks.jsx'
import SimpleGallery from './SimpleGallery.jsx'
import ListingJumpLinks from './ListingJumpLinks.jsx'
import ListingMapImage from './ListingMapImage.jsx'
import ListingImages from './ListingImages.jsx'
import ListingSection from './ListingSection.jsx'
import InformationMemorandum from './InformationMemorandum.jsx'
import SignInModal from './SignInModal.jsx'

import {
  auctionsPlusLogo,
  auctionsPlusLogoAlt,
  plainMarkerIcon,
  inexactMarkerIcon,
  singleIconUri,
  approximateIconUri,
  VIC_RENTAL_DEFAULT_APPLICATION,
  VIC_RENTAL_SOI,
} from '../../constants'

import EnquiryForm from '../container/EnquiryForm.jsx'
import SimilarListings from '../container/SimilarListings.jsx'


import type {
  LoadableBroker,
  LoadableMember,
  LoadableOrganisation,
} from '../../types'

import { categoriseListingAttachments } from '../../utils/data/listing/listings'

type Suburb = Object;

const videoOpenAttrs = eventMeta({
  category: 'Listing Video',
  action: 'Play',
})

const tourOpenAttrs = eventMeta({
  category: 'Listing Tour',
  action: 'Open',
})

const galleryOpenAttrs = eventMeta({
  category: 'Photo Gallery',
  action: 'Open',
})

const plansOpenAttrs = eventMeta({
  category: 'Listing Floorplans',
  action: 'Open',
})

const requestContractOpenAttrs = eventMeta({
  category: 'Request Contract',
  action: 'Open',
})

const enquiryOpenAttrs = eventMeta({
  category: 'Ask Question',
  action: 'Open',
})

const soiOpenAttrs = eventMeta({
  category: 'Statement of Information',
  action: 'Download',
})

const rentalApplyAttrs = eventMeta({
  category: 'Rental',
  action: 'Apply',
})

const bookInspectionMeta = eventMeta({
  category: 'Request Inspection',
  action: 'Open',
})

const defaultListingImage = process.env.WEBPACKED
  ? require('../../../assets/icons/dist/default_listing_image.svg')
  : ''

const dropSelfLinks = (listing, baseUrl) => {
  const { id, sourceId } = listing
  const normSourceId = sourceId.toLowerCase()
  const { host } = stdurl.parse(baseUrl)
  const normHost = host ? host.replace(/www\./, '').replace(/\..*/, '') : baseUrl
  const pattern = new RegExp(`(raywhite\\.(com|co\\.nz)|${normHost}).*/(${id}|${normSourceId})$`)

  return link => !pattern.test(link.url.toLowerCase())
}

function getTenancyUrl(
  listing,
  oneFormId,
  snugTeamId,
  tenappId,
  tenancyPortalCode,
  rentiAgencyCode,
  tenancyTrackerSubdomain,
  referrer,
  agent,
  office,
  tenappEndpoint,
  rentalFormUrl,
  resourceUrl,
) {
  const { id, sourceId } = listing

  if (oneFormId) return null

  if (tenappId) {
    return tenappUrl(tenappId, tenappEndpoint, listing, referrer, agent, office, resourceUrl)
  }

  if (rentiAgencyCode) return `https://renti.co/browse/${rentiAgencyCode}/${sourceId}`
  if (tenancyPortalCode) return `/api/tps/apply/${id}`
  if (snugTeamId) return snugUrl(snugTeamId, listing, referrer)

  if (tenancyTrackerSubdomain) {
    return `https://tenancytracker.co.nz/app/#/apply/${tenancyTrackerSubdomain}/${sourceId}`
  }

  return rentalFormUrl
}

type Media = {
  hash: string,
  code: string,
};

type Props = {
  authFailed: boolean,
  listing: Listing,
  members: Array<LoadableMember>,
  brokers: Array<LoadableBroker>,
  office: LoadableOrganisation,
  suburb?: Suburb,
  language: 'zh-Hans' | 'en',
  scrollAnchor: (Event) => void,
  setLanguage: (string) => void,
  baseUrl: string,
  rentalFormUrl?: string,
  oneFormId?: string,
  snugTeamId?: string,
  tenappId?: string,
  tenappEndpoint?: string,
  tenancyTrackerSubdomain?: string,
  tenancyPortalCode?: string,
  rentiAgencyCode?: string,
  irAccount?: string,
  irRent: boolean,
  irSell: boolean,
  useViewingTracker: boolean,
  user: Object,
  informationMemorandum: Object,
  showMapModal: boolean,
  showEnquiryModal: boolean,
  showInspectionModal: boolean,
  showSignInModal: boolean,
  showContractModal: boolean,
  enquiryEventLabel: ?string,
  galleryMedia?: Media | Array<Media>,
  galleryIndex: number,
  showJumpLinks: boolean,
  maps: ?any, // google maps API
  toggleEnquiryModal: () => void,
  toggleAgentsEnquiryModal: () => void,
  toggleMapModal: () => void,
  toggleSignInModal: () => void,
  sendForm: (Object, ?Object) => Promise<*>,
  toggleInspectionModal: () => void,
  toggleContractModal: () => void,
  setJumpLinkPoint: (?HTMLElement) => void,
  toggleGalleryModal: (?Object | ?Array<Object>, ?number) => () => void,
  showOfficeDetails: boolean,
  linkOfficeDetails: boolean,
  cdn: ?string,
  staticMap: boolean,
  beforeYouBidId: string,
  showLoanMarketBroker: boolean,
  showLoanMarketCalculator: boolean,
  calculatorPrice: ?number,
  organisationIds: Array<number>,
  displayOrganisationIds: Array<number>,
  enableRentalForms: boolean,
  onGalleryNavigation: ?() => void,
};

const prepareDescription = (description: string) => {
  const semantified = semantify(description)
  semantified.__html = linkify(semantified.__html, {
    className: null,
    attributes: {
      rel: 'noopener noreferrer',
    },
  })

  return semantified
}

const listingDescription = (description: string, clearingSaleItems: Array<ClearingSaleItem>) => {
  const html = description
    ? prepareDescription(description)
    : description

  if (description && clearingSaleItems.length) {
    if (isSimilarSubstring(description, clearingSaleItems[0].description)) {
      return <ListingClearingMetadata clearingSaleItems={clearingSaleItems} />
    }

    return [
      <div
        key="desc_content"
        className="pdp_description_content"
        dangerouslySetInnerHTML={html}
      />,
      <ListingClearingMetadata
        key="desc_clearing"
        clearingSaleItems={clearingSaleItems}
      />,
    ]
  }

  if (description) {
    return (
      <div
        className="pdp_description_content"
        dangerouslySetInnerHTML={html}
      />
    )
  }

  if (clearingSaleItems.length) {
    return <ListingClearingMetadata clearingSaleItems={clearingSaleItems} />
  }

  return null
}

const ListingPage = (props: Props) => {
  const {
    authFailed,
    listing,
    members,
    brokers,
    office,
    suburb = {},
    language,
    scrollAnchor,
    setLanguage,
    baseUrl,
    rentalFormUrl: _rentalFormUrl,
    oneFormId,
    snugTeamId: siteSnugTeamId,
    tenappId,
    tenappEndpoint,
    tenancyTrackerSubdomain,
    tenancyPortalCode,
    rentiAgencyCode,
    irAccount,
    irRent,
    irSell,
    useViewingTracker,
    showMapModal,
    showEnquiryModal,
    showSignInModal,
    showInspectionModal,
    showContractModal,
    enquiryEventLabel,
    galleryMedia,
    galleryIndex,
    showJumpLinks,
    toggleEnquiryModal,
    toggleAgentsEnquiryModal,
    toggleMapModal,
    toggleSignInModal,
    sendForm,
    toggleInspectionModal,
    toggleContractModal,
    setJumpLinkPoint,
    toggleGalleryModal,
    user,
    informationMemorandum,
    informationMemorandum: { sections: _sections = [] } = {},
    showOfficeDetails,
    linkOfficeDetails,
    cdn,
    beforeYouBidId,
    showLoanMarketBroker,
    // showLoanMarketCalculator, // Disabled
    calculatorPrice,
    organisationIds,
    displayOrganisationIds,
    staticMap,
    enableRentalForms,
    onGalleryNavigation,
  } = props
  // If offices have their own Snug team ID then that takes precedence
  const snugTeamId = office.settings?.snugTeamID || siteSnugTeamId

  const showLoanMarketCalculator = false

  const {
    id,
    sourceId,
    typeCode,
    statusCode,
    status,
    subTypeCode,
    categories: _categories,
    attachments = [],
    features: _features,
    address,
    address: {
      countryCode,
      stateCode,
      location = {},
    },
    images: _images,
    plans: _plans,
    auction,
    inspections,
    availableDate,
    links,
    garages,
    openSpaces,
    carports,
    extraFeatures,
    extraFeatures: { garaging: { carParkingDetails } = {} } = {},
    translations,
    clearingSaleItems = [],
    tender,
    setSaleDate,
    office: {
      organisationId,
    } = {},
  } = listing

  const listingUrl = getListingUrl(listing)
  const { title: originalTitle, description } = translations[language] || listing
  const isCommercial = ['COM', 'BUS'].indexOf(typeCode) !== -1
  const altLayout = isCommercial || typeCode === 'LVS'
  const broker = brokers.filter(b => !b.error)[0]

  const showLoanMarket = !!(
    showLoanMarketBroker
    && statusCode === 'CUR'
    && !isCommercial
    && isSellable(typeCode, subTypeCode)
  )

  const lmutm = stringify({
    utm_source: 'raywhite',
    utm_medium: 'referral',
    utm_campaign: 'officewebsite',
    utm_term: 'listing',
    utm_content: 'lmlogo',
  })
  // eslint-disable-next-line no-nested-ternary
  const brokerCount = brokers.filter(b => !b.error).length
  const loanMarketUrl = (
    (brokerCount > 1 && '/home-loans/loanmarket')
    || (
      countryCode === 'NZ'
        ? `${broker?.website || 'https://www.loanmarket.co.nz/advisers'}?${lmutm}`
        : `${broker?.website || 'https://my.loanmarket.com.au'}?${lmutm}`
    )
  )

  // Use the suburb location if none specified on the listing address
  const { lat: latitude, lon: longitude } = { ...suburb.location, ...location }
  const fuzzyLocation = !(location && location.lat && location.lon)
  const hasLocation = !!(latitude && longitude) && statusCode === 'CUR'

  const originalStreetAddress = stringifyAddress(address, { suburbFallback: false })
  const [, maybePropertyName, title] = typeCode === 'RUR'
    ? (/^(?:\s*"([^"]+)"(?:\s*-\s*)?)?[\s/]*(.*)/.exec(originalTitle) || [undefined, undefined, originalTitle])
    : [undefined, undefined, originalTitle]

  const [, maybePropertyName2, streetAddress] = (typeCode === 'RUR' && !maybePropertyName)
    ? (/^(?:\s*"([^"]+)"(?:\s*-\s*)?)?[\s*/](.*)/.exec(originalStreetAddress) || [undefined, undefined, originalStreetAddress])
    : [undefined, undefined, originalStreetAddress]
  const propertyName = maybePropertyName || maybePropertyName2
  const suburbAddress = stringifyAddress(address, {
    withStreet: false,
    withLocality: true,
    withState: true,
    withPostCode: countryCode !== 'NZ',
  })
  const fullAddress = [streetAddress, suburbAddress].filter(Boolean).join(', ')
  const addressPrimary = propertyName || streetAddress || stringifyAddress(address, {
    withStreet: false,
    withLocality: true,
    withState: false,
    withPostCode: false,
  })
  const addressSecondary = (
    (propertyName && fullAddress)
    || (streetAddress && suburbAddress)
    || `${address.stateCode || address.state} ${address.postCode || ''}`.trim()
  )
  const displayAddress = streetAddress || fullAddress
  const mayInspect = mayRequestInspection(statusCode, typeCode)
  const showEnquiryForm = mayShowEnquiryForm(statusCode)
  const showContractRequestForm = mayShowContractRequestForm(
    statusCode, typeCode, subTypeCode
  )
  const showVideo = mayShowVideo(statusCode)
  const isVicRental = address.stateCode === 'VIC' && typeCode === 'REN'
  // Replace default rental forms for VIC resi rentals
  const rentalFormUrl = isVicRental && /\/residential-offices\/[^/]+\.pdf$/.test(_rentalFormUrl || '')
    ? VIC_RENTAL_DEFAULT_APPLICATION
    : _rentalFormUrl

  const hasRentalForm = (
    typeCode === 'REN'
    && statusCode === 'CUR'
    && (
      (rentalFormUrl && enableRentalForms)
      || oneFormId
      || tenancyPortalCode
      || rentiAgencyCode
      || tenappId
      || (streetAddress && snugTeamId)
    )
  )

  const tenancyUrl = hasRentalForm && getTenancyUrl(
    listing,
    oneFormId,
    streetAddress ? snugTeamId : undefined, // Note: no snug for hidden addresses
    tenappId,
    tenancyPortalCode,
    rentiAgencyCode,
    tenancyTrackerSubdomain,
    `${baseUrl}${listingUrl}`,
    members[0],
    office,
    tenappEndpoint,
    enableRentalForms ? rentalFormUrl : undefined,
    cdn || baseUrl,
  )

  const isRentable = !!listing.rentFrequency
  let bookInspection = mayInspect && (
    <span
      key="bookInspection"
      className="btn"
      onClick={isCommercial ? toggleAgentsEnquiryModal : toggleInspectionModal}
      {...bookInspectionMeta}
    >
      {isCommercial ? 'Enquire with Agent' : 'Request an inspection'}
    </span>
  )

  if (
    !isCommercial && mayInspect && irAccount && ((irRent && isRentable) || (irSell && !isRentable))
  ) {
    const irUrl = 'https://www.inspectrealestate.com.au/RegisterOnline/Register.aspx'
    const params = {
      agentAccountName: irAccount,
      address: stringifyAddress(address, { withLocality: true }),
      type: undefined,
      imgURL: undefined,
    }
    if (!isRentable) {
      params.type = 'sale'
      params.imgURL = _images[0] && `${_images[0].url}?maxwidth=1200`
    }
    bookInspection = (
      <a
        key="bookInspection"
        className="btn"
        target="_blank"
        rel="noopener noreferrer"
        href={`${irUrl}?${stringify(params)}`}
        {...bookInspectionMeta}
      >
        Book an inspection
      </a>
    )
  }

  // Extract statement of information from attachments if possible
  const statementOfInformation = attachments.reduce((soi, { url, usage }) => {
    if (soi) return soi
    return usage === 'statementOfInformation' ? url : undefined
  }, false) || (isVicRental ? VIC_RENTAL_SOI : undefined)

  // Restructure attachments to be displayed on their own
  const attachmentsFiltered = attachments
    ? attachments.filter(attachment => attachment.usage !== 'statementOfInformation')
    : []

  const attachmentSection = {
    categories: [categoriseListingAttachments(id, attachmentsFiltered)],
    content: '',
    name: 'Documents',
    slug: 'documents',
  }

  const hasDocuments = (_sections || []).reduce((has, section) => {
    if (has) return has
    return section.slug === 'documents'
  }, !!attachmentsFiltered.length)

  // Copy sections list so that we can modify it
  const sections = [..._sections]

  // Merge IM & attachment data if both present
  if (!!informationMemorandum && attachmentsFiltered.length) {
    const documents = (sections || []).reduce((result, section) => {
      if (result) return result
      if (typeof section === 'object' && section.slug === 'documents') return section
      return undefined
    }, undefined)

    const { categories = [], name = 'Documents' } = documents || {}

    const combined: Object = {
      name,
      content: '',
      categories: [...categories, categoriseListingAttachments(id, attachmentsFiltered)],
    }

    // Replace the documents section, maintaining order if it was there already
    const index = sections.indexOf(documents)
    if (index === -1) {
      sections.push(combined)
    } else {
      sections.splice(index, 1, combined)
    }
  }

  const {
    videos: _videos,
    tours,
    plans,
    images,
    external: externalLinks = [],
  } = parseListingMedia({
    images: _images,
    plans: _plans,
    links,
  })

  const _tours = parseTours(tours)

  const videos = showVideo ? _videos : []
  const similarSlug = getTypeSlug(statusCode, typeCode, subTypeCode)

  /**
   * TODO: ATM we are just requesting an image with a max width of 1280, this
   * works in almost all situations but might be unnecessary if the image is
   * being rendered on a small device (or a very very large one). If we had
   * the viewport dimension in JS we would be able request an image that was
   * approriate for the display.
   */
  const [mainImage] = images
  const mainImageSrc = mainImage && (
    `${mainImage.url}?quality=90&width=${mainImage.width > 1600 ? '1600' : mainImage.width}`
  )
  const mainImageStyle = {
    // Note: second image provides a fallback if the first does not load
    backgroundImage: mainImageSrc
      // $FlowFixMe
      ? `url(${mainImageSrc}), url(${defaultListingImage})`
      // $FlowFixMe
      : `url(${defaultListingImage})`,
    backgroundSize: mainImage ? 'contain' : undefined,
  }
  const pdpFeature = (
    <div
      className="pdp_feature"
      style={mainImageStyle}
    >
      {statusCode !== 'CUR' && <div className="flag"><span>{status}</span></div>}
    </div>
  )

  const parkingDetails = describeParking(garages, openSpaces, carports, carParkingDetails)
  const features = parkingDetails
    ? _features.filter(x => !/^(single|double|triple) (garage|carport)$/i.test(x))
    : _features
  const pageTitle = propertyName
    ? `"${propertyName}" - ${createPageTitle(listing, language)}`
    : createPageTitle(listing, language)

  const structuredData = getStructuredData({ ...listing, title }, office, baseUrl, language)
  if (propertyName) structuredData.name = `"${propertyName}" - ${structuredData.name}`

  const threeDtour = tours.filter(tour => tour.code === VIRTUAL_TOUR && tour.type !== CRAPPY)[0]

  const beforeBid = statusCode === 'CUR' && labelLink(countryCode, stateCode, typeCode,
    _categories, members[0], listing, beforeYouBidId)

  const disclaimers = getDisclaimers(listing, informationMemorandum)
  const showLicenceCode = isSellable(typeCode, subTypeCode)
  const licenceCode = showLicenceCode && getOfficeLicenceCode(
    countryCode, stateCode, office.licenceCode
  )
  const sideOffice = showOfficeDetails && !!office && office.loaded && ([
    <h5 key="title" className="bold_font mini">Listed By:</h5>,
    linkOfficeDetails && (
      <Link
        key="name"
        className="mini anchor pdp_side_office_name"
        to={officeUrl(office)}
      >
        {office.fullName}
      </Link>
    ),
    !linkOfficeDetails && (
      <span
        key="name"
        className="mini anchor pdp_side_office_name"
        to={officeUrl(office)}
      >
        {office.fullName}
      </span>
    ),
    licenceCode && (
      <span
        key="licence"
        className="mini pdp_side_office_licence"
      >
        {licenceCode}
      </span>
    ),
  ])

  const nextSteps = [
    showContractRequestForm && (
      <a
        key="contract"
        className="mini anchor pdp_next_steps_contract"
        onClick={toggleContractModal}
        {...requestContractOpenAttrs}
      >
        Request contract
      </a>
    ),
    showEnquiryForm && (
      <a
        key="enquiry"
        className="mini anchor pdp_next_steps_question"
        onClick={toggleEnquiryModal}
        {...enquiryOpenAttrs}
      >
        {isCommercial ? 'Enquire with agent' : 'Ask a question'}
      </a>
    ),
    statementOfInformation && mayShowStatementOfInformation(statusCode) && (
      <a
        key="soi"
        className="mini anchor pdp_next_steps_contract"
        target="_blank"
        rel="noopener noreferrer"
        href={statementOfInformation}
        {...soiOpenAttrs}
      >
        {`${isVicRental ? 'S' : 'Price guide s'}tatement of information`}
      </a>
    ),
    hasRentalForm && oneFormId && (
      <OneFormLink
        key="oneform"
        id={oneFormId}
        listing={listing}
        agentName={(members[0] || office).fullName}
        agentEmail={(members[0] || office).email}
        officeName={office.fullName}
        className="mini anchor pdp_next_steps_rentalform"
      >
        Apply for this property
      </OneFormLink>
    ),
    hasRentalForm && !oneFormId && tenancyUrl && (
      <span key="tenancy" className="mini anchor pdp_next_steps_rentalform">
        {tenancyPortalCode ? (
          <TpsLink
            url={tenancyUrl}
            {...rentalApplyAttrs}
            data-ev-label="Next Steps"
          />
        ) : (
          <a
            href={tenancyUrl}
            rel="noopener noreferrer nofollow"
            target="_blank"
            {...rentalApplyAttrs}
            data-ev-label="Next Steps"
          >
            Apply for this property
          </a>
        )}
      </span>
    ),
    showLoanMarket && (
      <a
        key="broker"
        className="mini anchor pdp_next_steps_broker"
        onClick={scrollAnchor}
        href="#broker"
      >
        Talk to a mortgage{' '}
        {countryCode.toLowerCase() === 'au' ? 'broker' : 'adviser'}
      </a>
    ),
    showLoanMarketCalculator && (
      <a
        key="calculator"
        className="mini anchor pdp_next_steps_calculator"
        onClick={scrollAnchor}
        href="#calculator"
      >
        Calculate loan repayments
      </a>
    ),
    !!beforeBid && (
      <a
        key="report"
        className="mini anchor pdp_next_steps_download_report"
        href={beforeBid.link}
        rel="noopener noreferrer nofollow"
        target="_blank"
      >
        {beforeBid.label}
      </a>
    )
  ].filter(Boolean)
  const hasNextSteps = !!nextSteps.length
  if (hasNextSteps) {
    nextSteps.unshift(
      <h5
        key="title"
        id="next_steps"
        className="bold_font mini"
      >
        Next Steps:
      </h5>
    )
  }

  const altLayoutPlans = !!plans.length && altLayout

  const galleryItems = [
    ...videos,
    ...[].concat(...Object.values(_tours)).filter(t => (
      // $FlowFixMe
      t.code === VIRTUAL_TOUR && t.type !== CRAPPY
    )),
    ...images,
    ...plans,
  ]

  const openGallery = item => (event: SyntheticMouseEvent<*>) => {
    event.preventDefault()

    // $FlowFixMe
    props.onGalleryNavigation?.() // eslint-disable-line no-unused-expressions

    const index = typeof item === 'string'
      // strings are for a type code
      ? galleryItems.findIndex(i => i.code === item)
      // exact items can also be provided
      : galleryItems.indexOf(item)

    toggleGalleryModal(galleryItems, index === -1 ? 0 : index)
  }

  const validExternalLinks = externalLinks.filter(dropSelfLinks(listing, baseUrl))

  const nextStepExternalLinks = (!!validExternalLinks.length || altLayoutPlans) && [
    <h5 key="title" className="bold_font mini">Additional Information:</h5>,
    ...validExternalLinks.map(link => (
      <a
        key={link.url}
        className="mini anchor pdp_extra_links_link"
        target="_blank"
        rel="noopener noreferrer"
        href={link.url}
      >
        {linkTypeLabel(link.url, link.code)}
      </a>
    )),
    altLayoutPlans ? (
      <a
        key="enquiry"
        onClick={openGallery(FLOORPLAN)}
        className="mini anchor pdp_next_steps_floorplan"
        {...plansOpenAttrs}
      >
        {plans.length > 1 ? 'Floorplans' : 'Floorplan'}
      </a>
    ) : null,
  ]

  const jumpableLinks = (([
    images.length && {
      key: 'PHOTOS',
      label: images.length > 1 ? 'Photos' : 'Photo',
      onClick: openGallery(IMAGE),
      attrs: galleryOpenAttrs,
    },
    ...videos.map((v, i) => ({
      key: `VIDEO:${i}`,
      label: videos.length > 1 ? `Video ${i + 1}` : 'Video',
      onClick: openGallery(v),
      attrs: videoOpenAttrs,
    })),
    hasDocuments && statusCode === 'CUR' && ({
      key: 'DOCUMENTS',
      label: 'Documents',
      onClick: scrollAnchor,
      href: '#documents',
    }),
    plans.length && {
      key: 'FLOORPLANS',
      label: plans.length > 1 ? 'Floorplans' : 'Floorplan',
      onClick: openGallery(FLOORPLAN),
      attrs: plansOpenAttrs,
    },
    ...Object.keys(_tours).reduce((result, code) => {
      const tour = _tours[code]
      result.push(...tour.map((t, i) => (
        t.type === CRAPPY
          ? {
            key: `${t.code}:${i}`,
            label: tour.length > 1
              ? `${linkTypeLabel(t.url, t.code)} ${i + 1}`
              : linkTypeLabel(t.url, t.code),
            href: t.url,
            attrs: {
              target: '_blank',
              rel: 'noopener noreferrer',
            },
          }
          : {
            key: `TOUR:${i}`,
            label: tour.length > 1 ? `3D Tour ${i}` : '3D Tour',
            onClick: openGallery(t),
            attrs: tourOpenAttrs,
          }
      )))
      return result
    }, []),
    {
      key: 'DESCRIPTION',
      onClick: scrollAnchor,
      href: '#description',
      label: 'Description',
    },
    showEnquiryForm && {
      key: 'ASKQUESTION',
      onClick: scrollAnchor,
      href: '#agents',
      label: isCommercial ? 'Enquire with agent' : 'Ask a question',
    },
    hasLocation && {
      key: 'LOCATION',
      onClick: scrollAnchor,
      href: '#location',
      label: 'Location',
    },
    hasNextSteps && {
      key: 'NEXT_STEPS',
      onClick: scrollAnchor,
      href: '#next_steps',
      label: 'Next Steps',
    },
  ].filter(Boolean): Array<any>): Array<Object>)

  // Strip out extras, add anchor class
  const notMini = ['DESCRIPTION', 'ASKQUESTION', 'LOCATION', 'NEXT_STEPS']
  const miniJumpableLinks = jumpableLinks
    .filter(({ key }) => notMini.indexOf(key) === -1)
    .map(link => ({ ...link, className: classNames(link.className, 'anchor') }))
  const jumpLinks = !!jumpableLinks.length && (
    <ListingJumpLinks>{jumpableLinks}</ListingJumpLinks>
  )
  const miniJumpLinks = !!miniJumpableLinks.length && (
    <ListingJumpLinks>{miniJumpableLinks}</ListingJumpLinks>
  )
  return (
    <div
      className={classNames(
        'pg_pdp',
        `pg_pdp_type_${typeCode.toLowerCase()}`,
        {
          pg_pdp_layout_alt: altLayout,
          pg_pdp_sold_layout_al: altLayout && statusCode === 'SLD',
        }
      )}
    >
      <Helmet>
        <html lang={language} />
        <title>{pageTitle}</title>
        <meta name="description" content={excerptify(structuredData.description, 160)} />
        {!!Object.keys(translations).length && ([
          <link
            key="x-default"
            rel="alternate"
            href={`${baseUrl}${listingUrl}`}
            hrefLang="x-default"
          />,
          <link
            key="en"
            rel="alternate"
            href={`${baseUrl}${listingUrl}?language=en`}
            hrefLang="en"
          />,
          ...Object.keys(translations).map(hrefLang => (
            <link
              key={hrefLang}
              rel="alternate"
              href={`${baseUrl}${listingUrl}?language=${hrefLang}`}
              hrefLang={hrefLang}
            />
          )),
        ])}
      </Helmet>
      <TwitterSummaryCard
        title={structuredData.name}
        description={structuredData.description}
        image={mainImage && `${mainImage.url}?maxwidth=1280`}
        site={office && office.twitter}
        creator={members[0] && members[0].twitter}
      />
      <OpenGraph
        mayCropImage
        type="place"
        title={structuredData.name}
        description={structuredData.description}
        siteName={office && office.fullName}
        image={mainImage}
        url={`${baseUrl}${listingUrl}`}
        place={{ location: { latitude, longitude } }}
        video={videos[0] && videos[0].url}
      />
      <JsonLD>{structuredData}</JsonLD>
      <SimpleGallery
        media={galleryMedia}
        index={galleryIndex}
        handleClose={() => toggleGalleryModal()}
        onNext={onGalleryNavigation}
        onPrev={onGalleryNavigation}
      />
      <article className="pdp">
        <div
          className={classNames('pdp_slidein_jump_links show_charlie mini', {
            active: showJumpLinks,
          })}
        >
          {jumpLinks}
        </div>
        <div
          className="pdp_header"
        >
          {(mainImage ? (
            <a
              onClick={openGallery(IMAGE)}
              {...galleryOpenAttrs}
              style={{
                paddingBottom: `${mainImage.height / mainImage.width * 100}%`,
              }}
            >
              {pdpFeature}
            </a>
          ) : (
            <span>
              {pdpFeature}
            </span>
          ))}
          <div className="pdp_meta centered_text">
            <ListingDescriptor
              className="pdp_descriptor muted"
              listing={listing}
              describeOptions={{ withLocality: false }}
            />
            <div className="inner_sm">
              <h1 className={`pdp_address ${addressPrimary.length > 42 ? 'pdp_address_extra' : ''}`}>
                {(
                  addressPrimary === propertyName
                    ? <>&ldquo;{addressPrimary}&rdquo;</>
                    : addressPrimary
                )}
                {!!addressSecondary && (
                  <>
                    &nbsp;
                    <span>{addressSecondary}</span>
                  </>
                )}
              </h1>
              {listing.sourceId.match(/^APLUS/) && ([
                <img
                  key="ap_logo"
                  alt={createPriceString(listing)}
                  className="pdp_price pdp_price_image"
                  src={auctionsPlusLogo}
                />,
                <img
                  key="ap_logo_alt"
                  alt={createPriceString(listing)}
                  className="pdp_price pdp_price_image pdp_price_image_alt"
                  src={auctionsPlusLogoAlt}
                />,
              ])}
              <Price className="pdp_price" listing={listing} expandedString />
              {!!videos.length && (
              <a
                onClick={openGallery(VIDEO)}
                className="header_feature_button video_play_button"
                {...videoOpenAttrs}
              >
                <span className="sr">Play Video</span>
              </a>
              )}
              {!!threeDtour && (
                <a
                  onClick={openGallery(VIRTUAL_TOUR)}
                  className="header_feature_button three_d_tour_button"
                  {...tourOpenAttrs}
                >
                  <span className="sr">3D Tour</span>
                </a>
              )}
              {!!plans.length && (
                <a
                  onClick={openGallery(FLOORPLAN)}
                  className="header_feature_button floorplan_button"
                  {...plansOpenAttrs}
                >
                  <span className="sr">Floorplan</span>
                </a>
              )}
            </div>
            <ListingDetail
              {...listing}
              countryCode={countryCode}
            />
          </div>
        </div>
        {statusCode !== 'CUR' && (
          <ListingContacts
            listingId={id}
            organisationId={organisationId}
            organisationIds={displayOrganisationIds}
            id="agents"
            title={`${status} By`}
            subtitle={members.length ? 'Agents' : 'Office'}
            members={members}
            office={office}
            showLicenceCode={showLicenceCode}
            onClickEmail={toggleAgentsEnquiryModal}
          />
        )}
        <div className="pdp_events_wrap inner_lg">
          <EventBox
            key={listing.id}
            mayRequestInspection={mayInspect}
            showInspectionIcon={!isCommercial}
            events={{
              auction,
              inspections,
              tender,
              setSaleDate,
              available: availableDate,
            }}
            inspectionPrompt={isCommercial && (
              <p>
                Contact the agent if you'd like further information.
              </p>
            )}
            actions={[
              hasRentalForm && oneFormId && (
                <OneFormLink
                  key="oneFormLink"
                  id={oneFormId}
                  listing={listing}
                  agentName={(members[0] || office).fullName}
                  agentEmail={(members[0] || office).email}
                  officeName={office.fullName}
                  className="btn"
                  {...rentalApplyAttrs}
                  data-ev-label="Event Box"
                >
                  Apply for this property
                </OneFormLink>
              ),
              hasRentalForm && tenancyUrl && !!tenancyPortalCode && (
                <TpsLink
                  key="tenancyApplication"
                  url={tenancyUrl}
                  className="btn"
                  {...rentalApplyAttrs}
                  data-ev-label="Event Box"
                />
              ),
              hasRentalForm && tenancyUrl && !tenancyPortalCode && (
                <a
                  key="tenancyApplication"
                  href={tenancyUrl}
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                  className="btn"
                  {...rentalApplyAttrs}
                  data-ev-label="Event Box"
                >
                  Apply for this property
                </a>
              ),
              bookInspection,
            ].filter(x => x)}
          />
        </div>
        {!altLayout && jumpLinks && (
          <div
            className="inner_lg show_charlie"
            ref={setJumpLinkPoint}
          >
            <div className="pdp_jump_links full mini">
              {jumpLinks}
            </div>
          </div>
        )}
        {!altLayout && (
          <ListingImages
            id="pdp_images"
            images={images}
            largeCount={4}
            open={openGallery}
            openAttrs={galleryOpenAttrs}
            alt={fullAddress}
          />
        )}
        {!altLayout && miniJumpLinks && (
          <div className="inner hide_charlie">
            <div className="pdp_jump_links mini">
              {miniJumpLinks}
            </div>
          </div>
        )}
        <div className="pdp_description" id="description">
          <div className="inner_lg">
            <div className="tbl">
              <div className="pdp_description_header tbc top">
                <ListingDescriptor
                  component="h2"
                  className="pdp_descriptor muted show_charlie mini"
                  listing={listing}
                />
                {!!title && (
                  <h3 className="charlie" dangerouslySetInnerHTML={stripMarkup(title)} />
                )}
                <ListingDetail
                  {...listing}
                  countryCode={countryCode}
                />
                {hasNextSteps && (
                  <div className="show_charlie pdp_next_steps">
                    {nextSteps}
                  </div>
                )}
                {(!!validExternalLinks.length || altLayoutPlans) && (
                  <div className="show_charlie pdp_extra_links">
                    {nextStepExternalLinks}
                  </div>
                )}
                {sideOffice && (
                  <div className="show_charlie pdp_side_office">
                    {sideOffice}
                  </div>
                )}
              </div>
              <div className="pdp_description_content_wrap tbc top">
                {listingDescription(description, clearingSaleItems)}
                <ListingExtraFeatures
                  key={listing.id}
                  data={extraFeatures}
                  additional={features}
                />
                <ListingMetadata listing={listing} />
                {hasNextSteps && (
                  <div className="hide_charlie pdp_next_steps">
                    {nextSteps}
                  </div>
                )}
                {sideOffice && (
                  <div className="hide_charlie pdp_side_office">
                    {sideOffice}
                  </div>
                )}
                {!!validExternalLinks.length && (
                  <div className="hide_charlie pdp_extra_links">
                    {nextStepExternalLinks}
                  </div>
                )}
                <div className="pdp_description_links">
                  <div className="pdp_share mini">
                    <strong>Share:</strong>
                    <ShareLinks
                      snippet={pageTitle}
                      key={id}
                      url={structuredData.url}
                      linkedIn={isCommercial}
                    />
                  </div>
                  {!!Object.keys(translations).length && (
                    <div className="pdp_translation">
                      <Link
                        className={classNames('pdp_translation_link', { active: language === 'en' })}
                        to={`${listingUrl}?language=en`}
                        onClick={() => setLanguage('en')}
                      >
                        En
                      </Link>
                      {!!translations['zh-Hans'] && (
                        <Link
                          className={classNames('pdp_translation_link', { active: language === 'zh-Hans' })}
                          to={`${listingUrl}?language=zh-Hans`}
                          onClick={() => setLanguage('zh-Hans')}
                        >
                          中文
                        </Link>
                      )}
                    </div>
                  )}
                </div>
                {!!disclaimers.length && (
                  <div className="pdp_price_disclaimer muted">
                    {disclaimers.map(disclaimer => (
                      <p key={disclaimer}>
                        <em
                          dangerouslySetInnerHTML={{ __html: disclaimer }}
                        />
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <InvestmentMetadata listing={listing} />
          </div>
        </div>
        {statusCode === 'CUR' && !informationMemorandum && !!attachmentsFiltered.length && (
          <div className="pdp_commercial_info_wrap dark_bg">
            <div className="inner_smadium">
              <ListingSection
                {...attachmentSection}
                authFailed={authFailed}
                listingId={listing.id}
                authorised={!!user.userId}
                toggleSignInModal={toggleSignInModal}
              />
            </div>
          </div>
        )}
        {statusCode === 'CUR' && !!informationMemorandum && (
          <InformationMemorandum
            sections={sections}
            listingId={listing.id}
            authorised={informationMemorandum.isPublic || !!user.userId}
            toggleSignInModal={toggleSignInModal}
          />
        )}
        {showSignInModal && (
          <SignInModal
            handleClose={toggleSignInModal}
            dest="#documents"
          />
        )}
        {showContractRequestForm && showContractModal && (
          <Modal handleClose={toggleContractModal}>
            <ModalInner
              className="modal_form"
              title="Request Contract"
              subtitle={displayAddress}
            >
              <EnquiryForm
                sendForm={sendForm}
                submitLabel="Request Contract"
                extraData={{
                  subject: `Contract Request for ${id}`,
                }}
                sendMeta={{ eventType: 'Request Contract' }}
                defaultMessage={
                  `Please send me a contract for "${displayAddress}".`
                }
                handleClose={toggleContractModal}
              />
            </ModalInner>
          </Modal>
        )}
        {mayInspect && showInspectionModal && (
          <Modal handleClose={toggleInspectionModal}>
            <ModalInner
              className="modal_form"
              title="Request Inspection"
              subtitle={displayAddress}
            >
              {useViewingTracker && statusCode === 'CUR' && countryCode === 'NZ' && typeCode === 'REN' ? (
                <div
                  className="iframe_wrap"
                  style={{ paddingBottom: '100%' }}
                >
                  <iframe
                    title="Inspections"
                    src={`https://booking.viewingtracker.com/link/i/raywhitenz/${sourceId}`}
                  />
                </div>
              ) : (
                <EnquiryForm
                  sendForm={sendForm}
                  submitLabel="Request Inspection"
                  extraData={{
                    subject: `Inspection Request for ${id}`,
                  }}
                  sendMeta={{ eventType: 'Request Inspection' }}
                  defaultMessage={
                    `I'd like to arrange an inspection for "${displayAddress}".`
                  }
                  handleClose={toggleInspectionModal}
                />
              )}
            </ModalInner>
          </Modal>
        )}
        {hasLocation && showMapModal && (
          <Modal
            handleClose={toggleMapModal}
            full
          >
            <SingletonMap
              enforce={false}
              identifier="pdp_modal"
              mapId="streets-v10"
              zoom={fuzzyLocation ? 13 : 15}
              pitch={0}
              bearing={0}
              center={[longitude, latitude]}
              style={{
                width: '100vw',
                height: '100vh',
              }}
            >
              <div className="mb-control-container bottom-right">
                <ThreeDeeControl />
                <ZoomControl />
              </div>
              <BuildingShapeLayer />
              {!fuzzyLocation && (
                <BGImageMarker
                  latitude={latitude}
                  longitude={longitude}
                  dataUri={singleIconUri}
                />
              )}
              {fuzzyLocation && (
                <BGImageMarker
                  latitude={latitude}
                  longitude={longitude}
                  dataUri={approximateIconUri}
                  width="200px"
                />
              )}
            </SingletonMap>
          </Modal>
        )}
        {!isCommercial && showEnquiryForm && showEnquiryModal && (
          <Modal handleClose={toggleEnquiryModal}>
            <ModalInner
              className="modal_form"
              title={isCommercial ? 'Enquire with Agent' : 'Get in Touch'}
              subtitle={displayAddress}
            >
              <EnquiryForm
                sendForm={sendForm}
                handleClose={toggleEnquiryModal}
                submitLabel={isCommercial ? 'Send Enquiry' : undefined}
                extraData={{
                  subject: `Enquiry for ${displayAddress}`,
                }}
                sendMeta={{
                  eventType: 'Ask Question',
                  eventLabel: enquiryEventLabel,
                }}
              />
            </ModalInner>
          </Modal>
        )}
        {isCommercial && showEnquiryForm && showEnquiryModal && (
          <Modal handleClose={toggleEnquiryModal}>
            <ModalInner
              className="modal_form"
              title="Request Information"
              subtitle={displayAddress}
            >
              <EnquiryForm
                sendForm={sendForm}
                submitLabel="Request Information"
                extraData={{
                  subject: `Information Request for ${id}`,
                }}
                sendMeta={{ eventType: 'Request Information' }}
                defaultMessage=""
                handleClose={toggleEnquiryModal}
              />
            </ModalInner>
          </Modal>
        )}
        {altLayout && (
          <ListingImages
            id="pdp_images_alt"
            images={images}
            largeCount={1}
            open={openGallery}
            openAttrs={galleryOpenAttrs}
            alt={fullAddress}
          />
        )}
        {statusCode === 'CUR' && (
          <ListingContacts
            listingId={id}
            organisationIds={organisationIds}
            id="agents"
            title={members.length ? 'Agents' : 'Office'}
            subtitle="Contact"
            members={members}
            office={office}
            showLicenceCode={showLicenceCode}
            onClickEmail={toggleAgentsEnquiryModal}
          >
            {showEnquiryForm && (
              <div className="pdp_contact_prompt">
                <button
                  type="button"
                  className="btn"
                  onClick={toggleAgentsEnquiryModal}
                  {...enquiryOpenAttrs}
                  data-ev-label="Agents"
                >
                  {isCommercial ? 'Enquire with agent' : 'Ask a question'}
                </button>
              </div>
            )}
          </ListingContacts>
        )}
        {hasLocation && (
          <div className="pdp_map_outer" id="location">
            <div
              className={classNames('pdp_map_wrap', {
                pdp_map_wrap_static: staticMap,
                pdp_map_wrap_dynamic: !staticMap,
              })}
            >
              {!!staticMap && (
                <ListingMapImage
                  onClick={toggleMapModal}
                  latitude={latitude}
                  longitude={longitude}
                  cdn={cdn}
                  icon={fuzzyLocation ? inexactMarkerIcon : plainMarkerIcon}
                  zoom={fuzzyLocation ? 12 : 14}
                  alt={`Map of ${fullAddress}`}
                />
              )}
              {!staticMap && !showMapModal && (
                <React.Fragment>
                  <div
                    className="pdp_map_dynamic_overlay"
                    onClick={toggleMapModal}
                  />
                  <SingletonMap
                    enforce
                    identifier="pdp_inline"
                    zoom={fuzzyLocation ? 13 : 15}
                    pitch={0}
                    bearing={0}
                    center={[longitude, latitude]}
                    mapId="light-v10"
                    renderChildrenInPortal
                  >
                    {!fuzzyLocation && (
                      <BGImageMarker
                        latitude={latitude}
                        longitude={longitude}
                        dataUri={singleIconUri}
                      />
                    )}
                    {fuzzyLocation && (
                      <BGImageMarker
                        latitude={latitude}
                        longitude={longitude}
                        dataUri={approximateIconUri}
                        width="200px"
                      />
                    )}
                  </SingletonMap>
                </React.Fragment>
              )}
              <span
                className="pdp_map_enlarge"
                onClick={toggleMapModal}
              />
            </div>
          </div>
        )}
        {showLoanMarket && (
          <div
            id="broker"
            className="pdp_broker lm"
          >
            <header
              className={classNames('lm_header', {
                lm_header_no_brokers: brokers.length !== 1,
              })}
            >
              <a
                href={loanMarketUrl}
                target="_blank"
                rel="noopener noreferrer"
                {...eventMeta({
                  category: 'Outbound',
                  action: 'Click',
                  label: 'LoanMarket',
                })}
              >
                <h3 className="centered_text centered_text lm_logo">
                  <span className="sr">Loan Market</span>
                </h3>
              </a>
              <p className="subheading centered_text inner_sm">
                Loan Market mortgage brokers aren’t owned by a bank, they work for you.
                {' '}
                With access to over {countryCode === 'NZ' ? 20 : 60} lenders they’ll work with you to find a
                {' '}
                competitive loan to suit your needs.
              </p>
            </header>
            <div className="lm_agent_wrap">
              {brokers.length === 1 && !!broker && (
                <BrokerCard
                  broker={broker}
                  office={office.fullName}
                  utmTerm="listing"
                />
              )}
              <div className="lm_agent_contact centered_text">
                {brokers.length > 1 ? (
                  <Link
                    to="/home-loans/loanmarket"
                    className="btn"
                  >
                    Contact Our Mortgage Advisers
                  </Link>
                ) : (
                  <a
                    href={(
                      (broker && broker.website)
                        ? `${broker.website}?${stringify({
                          utm_source: 'raywhite',
                          utm_medium: 'referral',
                          utm_campaign: 'officewebsite',
                          utm_term: 'listing',
                          utm_content: 'brokerbutton',
                        })}`
                        : loanMarketUrl
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn"
                    {...eventMeta({
                      category: 'Outbound',
                      action: 'Click',
                      label: 'LoanMarket',
                    })}
                  >
                    Contact
                    {' '}
                    {broker ? broker.firstName : 'Loan Market'}
                  </a>
                )}
              </div>
            </div>
            {showLoanMarketCalculator && (
              <div
                id="calculator"
                className="pdp_lm_calculator_wrap dark"
              >
                <div className="inner_sm">
                  <LoanMarketWidget listingId={id} price={calculatorPrice} />
                </div>
              </div>
            )}
          </div>
        )}
        <SimilarListings
          listing={listing}
          slice={4}
          cardOptions={{ addressComponent: 'h5' }}
        >
          {(hasListings, similar) => hasListings && (
            <div key={listing.id} className="pdp_related" id="related">
              <div className="inner_lg">
                <span className="muted mini preheading">Related</span>
                <h3 className="charlie centered_text">You Might Also Like&hellip;</h3>
                <div className="pdp_related_proplist">
                  {similar}
                </div>
              </div>
              {similarSlug && (
                <div className="centered_text">
                  <Link
                    className="btn"
                    to={`/properties/${similarSlug}`}
                  >
                    More like this
                  </Link>
                </div>
              )}
            </div>
          )}
        </SimilarListings>
      </article>
    </div>
  )
}

export default ListingPage

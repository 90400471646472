/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import classNames from 'classnames'
import { scrollToId } from '@raywhite/browser-utils/lib/browser/smoothScroll'
import flagged from '@raywhite/helpers-utils/lib/helpers/flags'
import { telLink, eventMeta } from '@raywhite/helpers-utils/lib/helpers/link'
import { getOfficeLicenceCode } from '@raywhite/helpers-utils/lib/helpers/rules'
import { toSlug } from '@raywhite/helpers-utils/lib/helpers/string'
import { processCategories } from '@raywhite/functionalities-utils/lib/functionalities/services'
import { officeUrl } from '@raywhite/data-utils/lib/data/member/office'
import { strip } from '@raywhite/markup'

import Shielded from 'react-shielded'
import Loader from '../presentational/Loader.jsx'
import Link from '../presentational/Link.jsx'
import withMenus from '../hocs/withMenus'
import withSiteMetadata from '../hocs/withSiteMetadata'
import withContext from '../hocs/withContext'
import SocialIcons from '../presentational/SocialIcons.jsx'

const appraisalMeta = eventMeta({
  category: 'Appraisal',
  action: 'Open',
  label: 'Site',
})

const enquiryMeta = eventMeta({
  category: 'Ask Question',
  action: 'Open',
  label: 'Site',
})

const OfficeAddress = ({ address, className }) => {
  const {
    streetAddress = '',
    locality = '',
    state,
    stateCode,
    postCode,
    floorNumber,
  } = address

  if (!streetAddress && !locality) return null

  /* eslint-disable react/no-array-index-key */
  return (
    <p className={classNames('footer_office_address', className)}>
      {/^\d+$/.test(floorNumber) && 'Level '}
      {floorNumber}
      {floorNumber && <br />}
      {streetAddress.split(/\s*[,\n]\s*/g).map((l, i) => ([
        <span key={`l${i}`}>{l}</span>,
        <br key={`b${i}`} />,
      ]))}
      {locality.trim()}
      {(stateCode || state) ? `, ${stateCode || state} ` : ' '}
      {postCode}
    </p>
  )
  /* eslint-enable react/no-array-index-key */
}

OfficeAddress.propTypes = {
  address: PropTypes.object.isRequired,
  className: PropTypes.string,
}

const FooterOffice = props => {
  const {
    emailOverride,
    org,
    org: {
      organisationId,
      fullName,
      primaryPhone,
      address,
      postalAddress,
      roles = [],
    },
    expand,
    isMultiple,
    toggleEnquiryModal,
  } = props
  const email = emailOverride || org.email
  const link = isMultiple ? `/about/${toSlug(fullName)}/${organisationId}` : '/about'
  const isVirtual = roles.indexOf('Virtual Office') !== -1

  if (expand) {
    return (
      <div className="footer_office" key={organisationId}>
        <Link to={link}>
          <h4 className="foxtrot">{fullName}</h4>
        </Link>
        {!isVirtual && !!address && <OfficeAddress address={address} />}
        <ul className="footer_office_contact">
          {primaryPhone && (
            <li className="contact_link_landline">
              <span>
                {telLink(primaryPhone, { 'data-ev-label': fullName })}
              </span>
            </li>
          )}
          {email && (
            <li className="contact_link_email">
              <span>
                <a onClick={toggleEnquiryModal}>{email}</a>
              </span>
            </li>
          )}
        </ul>
        {!!postalAddress && <OfficeAddress address={postalAddress} className="postal_address" />}
      </div>
    )
  }

  return (
    <div className="footer_office" key={organisationId}>
      <Link to={officeUrl(org)}>
        <h4 className="foxtrot">{fullName}</h4>
      </Link>
    </div>
  )
}

FooterOffice.propTypes = {
  org: PropTypes.object.isRequired,
  expand: PropTypes.bool.isRequired,
  isMultiple: PropTypes.bool,
  toggleEnquiryModal: PropTypes.func.isRequired,
  emailOverride: PropTypes.string,
}

FooterOffice.defaultProps = {
  expand: false,
}

class FooterPrimaryMenus extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
  }

  state = {
    activeMenu: undefined,
  }

  activateMenu(activeMenu) {
    this.setState({ activeMenu })
  }

  render() {
    const { items } = this.props

    if (!items.length) {
      return null
    }

    return (
      <div className="tbl">
        {items.map(({ label, path, children = [], onClick: handleClick }, index) => (
          <div className="tbc" key={`${label}${path}`}>
            <nav>
              <ul>
                <li>
                  {(path && path !== '#') ? (
                    <Link
                      to={path}
                      className={classNames('footer_top_level_link foxtrot', {
                        anchor: children.length === 0,
                        single_item_menu: children.length === 0,
                      })}
                    >
                      {strip(label)}
                    </Link>
                  ) : (
                    <span
                      onClick={handleClick}
                      className={classNames('footer_top_level_link foxtrot', {
                        anchor: !!handleClick,
                        single_item_menu: children.length === 0,
                      })}
                    >
                      {strip(label)}
                    </span>
                  )}
                  <span
                    className="footer_top_level_link foxtrot"
                    onClick={() => this.activateMenu(index)}
                  >
                    {label}
                  </span>
                  <ul className={this.state.activeMenu === index ? 'active' : undefined}>
                    {children.map(c => (!!(c.path || c.onClick) && (
                      <li key={c.label}>
                        {c.path ? (
                          <Link to={c.path}>{strip(c.label)}</Link>
                        ) : (
                          <span onClick={c.onClick} className="anchor">{strip(c.label)}</span>
                        )}
                      </li>
                    )))}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        ))}
      </div>
    )
  }
}

// Build a menu-like structure out of the type category information
const typeMenu = (categories, forms) => ['Buy', 'Rent', 'Sell'].reduce((result, label) => {
  const item = {
    label,
    path: `/${label.toLowerCase()}`,
    children: [],
  }

  const children = categories[label.toLowerCase()] || []
  item.children.push(...children.map(({ name, slug }) => ({
    label: name,
    path: `${item.path}/${slug}`,
    children: [],
  })))

  // Inject forms as well
  item.children.push(...(forms[label.toLowerCase()] || []))

  result.push(item)
  return result
}, [])

class Footer extends Component {
  static propTypes = {
    orgs: PropTypes.array.isRequired,
    orgsLoaded: PropTypes.bool.isRequired,
    primaryOrg: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    _window: PropTypes.object,
    menu: PropTypes.object,
    toggleAppraisalModal: PropTypes.func.isRequired,
    toggleEnquiryModal: PropTypes.func.isRequired,
    serviceForms: PropTypes.object.isRequired,
    useServicePages: PropTypes.bool.isRequired,
    categories: PropTypes.object,
    theme: PropTypes.oneOf(['commercial', 'residential']),
    defaultEmail: PropTypes.string,
  }

  /**
   * TODO: The code that we're running in this hook is the
   * fallback for loading the homepage with a particular
   * form modal displayed. ATM, this is the only way to
   * perform an initial render with a form in the viewport,
   * but we should implement a set of pages that contain
   * only the forms of interest.
   *
   * TODO: It's likely that this logic doesn't actually
   * belong here either... it's been placed in the footer
   * since this is the only component that renders
   * forms on the homepage.
   */
  componentDidMount() {
    const { _window } = this.props
    const { hash, pathname } = _window ? _window.location : {}

    if (pathname && pathname === '/' && hash) {
      if (hash === '#contact') {
        this.props.router.replace('/')
        this.props.toggleEnquiryModal()
      }

      if (hash === '#appraisal') {
        this.props.router.replace('/')
        this.props.toggleAppraisalModal()
      }
    }
  }

  shouldComponentUpdate({ orgsLoaded, menu, categories }) {
    return (
      orgsLoaded !== this.props.orgsLoaded
      || categories !== this.props.categories
      || menu !== this.props.menu
    )
  }

  render() {
    const {
      defaultEmail,
      primaryOrg,
      primaryOrg: {
        address: {
          countryCode,
          stateCode,
          locality,
        } = {},
        roles = [],
      },
      orgs,
      orgsLoaded,
      menu: {
        loaded: menusLoaded,
        menus: {
          footer_right: rightMenu = [],
          primary: primaryMenu = [],
        },
      },
      useServicePages,
      categories,
      serviceForms,
      _window,
      theme,
      toggleEnquiryModal,
      toggleAppraisalModal,
    } = this.props

    if (!orgsLoaded) {
      return <footer id="footer" className="main_footer"><Loader /></footer>
    }

    const isVirtual = roles.indexOf('Virtual Office') !== -1
    const mainMenu = !useServicePages
      ? primaryMenu
      : typeMenu(categories, serviceForms)

    const licenceCode = getOfficeLicenceCode(countryCode, stateCode, primaryOrg.licenceCode)

    // NZ needs to link to REAA info
    const legalMenu = countryCode !== 'NZ'
      ? rightMenu
      : [...rightMenu, {
        path: 'https://www.rea.govt.nz/',
        label: 'REA',
      }]

    return (
      <footer id="footer" className="main_footer">
        <div className="inner_lg">
          <div className="footer_button_bar tbl">
            <div className="tbc">
              <Link to="/" className="logo footer_logo"><span>Ray White</span></Link>
            </div>
            <div className="tbc">
              {theme !== 'commercial'
              && (
              <a
                onClick={toggleAppraisalModal}
                className="btn light"
                {...appraisalMeta}
              >
                Book a Free Appraisal
              </a>
              )}
              <a
                onClick={toggleEnquiryModal}
                className="btn invisible_light"
                {...enquiryMeta}
              >
                Contact Us
              </a>
            </div>
            <div className="tbc footer_social">
              {countryCode === 'NZ' && (
                <Shielded className="shielded" />
              )}
              <SocialIcons social={primaryOrg} />
            </div>
          </div>
          <div className="footer_nav_bar tbl">
            <div className="tbc">
              <div className="footer_offices">
                {orgs.map(org => (
                  <FooterOffice
                    key={org.organisationId}
                    org={org}
                    emailOverride={org === orgs[0] ? defaultEmail : undefined}
                    isMultiple={orgs.length > 1}
                    toggleEnquiryModal={toggleEnquiryModal}
                    expand={orgs.length > 2
                      ? org === orgs[0]
                      : (org === orgs[0] || org === orgs[1])}
                  />
                ))}
              </div>
              <div className="hide_charlie footer_office_social">
                {countryCode === 'NZ' && (
                  <Shielded className="shielded" />
                )}
                <SocialIcons social={primaryOrg} />
              </div>
            </div>
            <div className="tbc">
              <FooterPrimaryMenus items={mainMenu.slice(0, 3)} />
            </div>
          </div>
          <div className="back_top">
            <a onClick={scrollToId(_window)} href="#app">
              Back to top
            </a>
          </div>
          <div className="footer_copyright_bar muted tbl">
            <div className="tbc">
              <p className="copyright">
                &copy;
                {' '}
                {primaryOrg.fullName}
                .
                {' '}
                {!isVirtual && `Based in ${locality}.`}
                {licenceCode && ` ${licenceCode}.`}
              </p>
            </div>
            <div className="tbc">
              {menusLoaded && !!legalMenu.length && (
                <nav className="footer_legal_links">
                  <ul>
                    {legalMenu.map(({ path, label }) => (
                      <li key={`${path}${label}`}>
                        <Link to={path}>
                          {strip(label)}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </nav>
              )}
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

const mapStateToProps = (state, { siteMetadata: { typeKey } }) => {
  const useServicePages = flagged('servicePages', state)
  const {
    config: {
      options: {
        defaultEmail,
      },
    },
  } = state

  return {
    useServicePages,
    defaultEmail,
    categories: (useServicePages && typeKey) ? processCategories(typeKey) : {},
    theme: state.config.theme,
  }
}

export default compose(
  withMenus,
  withSiteMetadata,
  connect(mapStateToProps),
  withRouter,
  withContext('_window'),
)(Footer)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {
  fetchListing,
  getListing,
  fetchListingsMeta,
  getListingsMeta as getListingsMetaFromStore,
} from '@raywhite/redux/lib/listing'
import {
  extractPrice,
} from '@raywhite/data-utils/lib/data/listing/formatting'
import { getSuburb, loadSuburbs } from '@raywhite/redux/lib/suburb'
import {
  getMember,
  loadMembers,
  loadMembersByIds,
  getOrganisationMembers,
} from '@raywhite/redux/lib/member'
import { getOrganisation, loadOrganisations } from '@raywhite/redux/lib/org'
import { elementRect } from '@raywhite/browser-utils/lib/browser/browser'
import scroll, { scrollToId } from '@raywhite/browser-utils/lib/browser/smoothScroll'
import onScroll from '@raywhite/browser-utils/lib/browser/onScroll'
import { unlazyAll } from '@raywhite/helpers-utils/lib/helpers/async'
import { mayShowListing, getOfficeLicenceCode } from '@raywhite/helpers-utils/lib/helpers/rules'
import { submitForm } from '@raywhite/functionalities-utils/lib/functionalities/form'

import { getBrokers, loadBrokers } from '../../redux/modules/brokers'
import Loader from '../presentational/Loader.jsx'
import NotFoundPagePage from '../presentational/NotFoundPage.jsx'
import ErrorPage from '../presentational/ErrorPage.jsx'
import withContext from '../hocs/withContext'
import ListingPage from '../presentational/ListingPage.jsx'
import { patchUserData, getUserData } from '../../redux/modules/user'
import withUser from '../hocs/withUser'
import { bits32 } from '../../utils/integer'

/**
 * Figure out whether we should force the office details to show.
 *
 * We want to show their details if
 * - we need to show their licence code for legal reasons OR
 * - the office has elected to do so
 */
function shouldShowOfficeDetails(licenceCode, office, config) {
  if (!office) return false
  const {
    primaryOrganisationId,
    displayOrganisationIds,
    options: {
      showOfficeDetails,
      showHiddenOffices,
    },
  } = config
  const { organisationId } = office
  const isHidden = displayOrganisationIds.indexOf(organisationId) === -1
  const isOnlyPrimary = (
    displayOrganisationIds.length === 1
    && primaryOrganisationId === organisationId
  )

  return (
    // Show because of the licence code
    !!(licenceCode && (isHidden || !isOnlyPrimary))
    // Show because they've elected to do so, unless they're the only displayed office
    || (!isOnlyPrimary && showOfficeDetails && (showHiddenOffices || !isHidden))
  )
}

export class ListingPageContainer extends Component {
  static propTypes = {
    authFailed: PropTypes.bool.isRequired,
    listing: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    getListings: PropTypes.func.isRequired,
    getListingsMeta: PropTypes.func.isRequired,
    getBrokers: PropTypes.func.isRequired,
    getMembers: PropTypes.func.isRequired,
    getSuburbs: PropTypes.func.isRequired,
    getOrganisations: PropTypes.func.isRequired,
    _window: PropTypes.object,
    organisationIds: PropTypes.array.isRequired,
    displayOrganisationIds: PropTypes.array.isRequired,
    fetch: PropTypes.func.isRequired,
    members: PropTypes.array.isRequired,
    brokers: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    rentalFormUrl: PropTypes.string,
    office: PropTypes.object,
    baseUrl: PropTypes.string.isRequired,
    informationMemorandum: PropTypes.object,
    tenancyTrackerSubdomain: PropTypes.string,
    oneFormId: PropTypes.string,
    snugTeamId: PropTypes.string,
    tenappId: PropTypes.string,
    tenappEndpoint: PropTypes.string,
    tenancyPortalCode: PropTypes.string,
    rentiAgencyCode: PropTypes.string,
    irAccount: PropTypes.string,
    irRent: PropTypes.bool,
    irSell: PropTypes.bool,
    useViewingTracker: PropTypes.bool,
    suburb: PropTypes.object,
    showLoanMarketBroker: PropTypes.bool,
    language: PropTypes.oneOf(['zh-Hans', 'en']).isRequired,
    router: PropTypes.object,
    user: PropTypes.object,
    showOfficeDetails: PropTypes.bool.isRequired,
    linkOfficeDetails: PropTypes.bool.isRequired,
    cdn: PropTypes.string,
    beforeYouBidId: PropTypes.string,
    showLoanMarketCalculator: PropTypes.bool,
    calculatorPrice: PropTypes.number,
    staticMap: PropTypes.bool.isRequired,
    enableRentalForms: PropTypes.bool,
  }

  static getDerivedStateFromProps(props, state) {
    if (state.lastListingId === props.listing.id) return null
    return {
      lastListingId: props.listing.id,
      showJumpLinks: false,
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      lastListingId: props.listing.id,
      showMapModal: false,
      showEnquiryModal: false,
      showInspectionModal: false,
      showContractModal: false,
      showSignInModal: false,
      enquiryEventLabel: undefined,
      media: null,
      index: 0,
      showJumpLinks: false,
    }
  }


  componentDidMount() {
    const {
      dispatch,
      getMembers,
      brokers,
      getBrokers: getBrokersApi,
      getOrganisations,
      getListings,
      getListingsMeta,
      listing,
      organisationIds,
      _window,
      office: {
        countryCode,
      } = {},
    } = this.props

    const { agents = [] } = listing

    const nativeAgentsIds = agents
      .filter(agent => !agent.conjunctional)
      .map(({ memberId }) => memberId)

    const nonNativeAgentsIds = agents
      .filter(agent => agent.conjunctional)
      .map(({ memberId }) => memberId)

    const brokerIds = brokers.map(({ brokerId }) => brokerId)

    dispatch(loadMembers(getMembers, organisationIds, nativeAgentsIds))
    dispatch(loadMembersByIds(getMembers, nonNativeAgentsIds))

    dispatch(fetchListingsMeta(getListingsMeta, [listing.id]))

    if (countryCode) {
      dispatch(loadBrokers(getBrokersApi, countryCode.toLowerCase(), brokerIds))
    }

    this.constructor.fetchData(
      dispatch,
      { listingId: listing.id },
      {
        getListings,
        getOrganisations,
        organisationIds: listing.office && listing.office.organisationId
          ? [listing.office.organisationId] : [],
      },
    )

    this.cancelOnScroll = onScroll(this.maybeShowJumpLinks, _window)

    this.maybeLoadListingSuburb(this.props)

    this.maybeTrackEvent()
  }

  componentDidUpdate(before) {
    const {
      getMembers,
      dispatch,
      listing,
      _window,
      informationMemorandum,
    } = this.props

    const { agents = [] } = listing

    this.maybeLoadData(this.props)

    const nonNativeAgentsIds = agents
      .filter(agent => agent.conjunctional)
      .map(({ memberId }) => memberId)

    dispatch(loadMembersByIds(getMembers, nonNativeAgentsIds))

    // Scroll to the #documents if requested and now present, it's not SSR so we
    // can't just rely on the browser to do it.
    if (!_window) return

    this.maybeTrackEvent()

    const hasUpdatedIm = informationMemorandum && !before.informationMemorandum
    const wantsDocSection = _window.location.hash === '#documents'
    const el = _window.document.querySelector('#documents')
    if (wantsDocSection && hasUpdatedIm && el) scroll(_window, 'documents')
  }

  componentWillUnmount() {
    const { _window } = this.props
    if (this.cancelOnScroll) this.cancelOnScroll()

    // Clear current listing on unmount
    _window?.dataLayer?.push({ // eslint-disable-line no-unused-expressions
      galleryViews: 0,
      listing: undefined,
    })
  }

  setJumpLinkPoint = el => {
    this.jumpLinkPoint = el
  }

  maybeTrackGallery = () => {
    const { _window } = this.props
    if (!_window?.dataLayer) return
    const galleryViews = _window.dataLayer.findLast(dl => 'galleryViews' in dl)?.galleryViews
    _window.dataLayer.push({
      event: 'listingGalleryView',
      galleryViews: (galleryViews || 0) + 1,
    })
  }

  maybeTrackEvent() {
    const {
      _window,
      listing,
    } = this.props

    if (listing.loaded && !listing.error && _window?.dataLayer) {
      // Push listing meta into the data layer
      const newLoad = _window.dataLayer.findLast(dl => 'listing' in dl)?.listing?.id !== listing.id
      if (newLoad) {
        // eslint-disable-next-line no-unused-expressions
        _window?.dataLayer?.push({
          event: 'listingView',
          galleryViews: 0,
          listing: {
            id: listing.id,
            address: listing.address,
            type: listing.type,
          },
        })
      }
    }
  }

  maybeLoadData(props) {
    const { dispatch, getListings, getListingsMeta, listing, getOrganisations } = props
    dispatch(fetchListingsMeta(getListingsMeta, [listing.id]))
    this.constructor.fetchData(dispatch, { listingId: listing.id }, {
      getListings,
      getOrganisations,
      organisationIds: listing.office && listing.office.organisationId
        ? [listing.office.organisationId]
        : [],
    })

    this.maybeLoadListingSuburb(props)
    this.maybeUpdateUrlLanguage(props)
  }

  maybeLoadListingSuburb(props) {
    const {
      dispatch,
      getSuburbs,
      listing: {
        loaded,
        notFound,
        error,
        address: { suburbId } = {},
      },
    } = props

    // Load the suburb data (including location) if no listing location is available
    if (loaded && !error && !notFound && suburbId) {
      dispatch(loadSuburbs(getSuburbs, { id: suburbId }, 1, [suburbId]))
    }
  }

  /**
   * Update the URL with the current language on multilingual listings if the
   * indicated language doesn't match the current one.
   */
  maybeUpdateUrlLanguage(props) {
    const {
      listing: { translations = {} },
      language,
      location: { pathname, query },
      router,
    } = props

    if (!(Object.keys(translations).length)) return
    if (query.language === language) return

    // Invalid language given
    if (!(language === 'en' || translations[language])) {
      router.replace({ pathname, query: { ...query, language: undefined } })
      return
    }

    // Language being shown doesn't match query language
    router.replace({ pathname, query: { ...query, language } })
  }

  maybeShowJumpLinks = () => {
    if (!this.jumpLinkPoint) return
    const showJumpLinks = elementRect(this.jumpLinkPoint).bottom < 0
    if (showJumpLinks !== this.state.showJumpLinks) this.setState({ showJumpLinks })
  }

  toggleMapModal = (event) => {
    event.preventDefault()

    const { _window } = this.props
    const { showMapModal } = this.state
    const nextShowMapModal = !showMapModal

    if (_window && _window.dataLayer) {
      _window.dataLayer.push({
        event: 'rwCustom',
        rwCustomData: {
          category: 'Map',
          action: nextShowMapModal ? 'Expand' : 'Contract',
          label: undefined,
        },
      })
    }
    this.setState({ showMapModal: nextShowMapModal })
  }

  toggleContractModal = (event) => {
    event.preventDefault()

    this.setState(state => ({
      showContractModal: !state.showContractModal,
    }))
  }

  toggleSignInModal = (event) => {
    event.preventDefault()

    this.setState(state => ({
      showSignInModal: !state.showSignInModal,
    }))
  }

  toggleEnquiryModal = (event) => {
    event.preventDefault()

    this.setState(state => ({
      showEnquiryModal: !state.showEnquiryModal,
      enquiryEventLabel: undefined,
    }))
  }

  toggleInspectionModal = (event) => {
    event.preventDefault()

    this.setState(state => ({
      showInspectionModal: !state.showInspectionModal,
      enquiryEventLabel: 'Inspections',
    }))
  }

  toggleAgentsEnquiryModal = (event) => {
    event.preventDefault()

    this.setState(state => ({
      showEnquiryModal: !state.showEnquiryModal,
      enquiryEventLabel: 'Agents',
    }))
  }

  toggleGalleryModal = (media, index) => {
    if (!media) {
      this.setState({ media: null, index: 0 })
      return
    }

    this.setState({ media, index: index || 0 })
  }

  sendForm = (data, meta = {}) => (
    submitForm(
      this.props.fetch,
      this.props._window && this.props._window.dataLayer,
      meta.eventType || 'listing-enquiry',
      `/api/contact/listing/${this.props.listing.id}`,
      data,
      'Unable to send enquiry.',
      meta.eventLabel,
    )
  )

  render() {
    const { listing } = this.props

    if (listing.notFound) {
      return <NotFoundPagePage />
    }

    if (listing.loading || !listing.loaded) {
      return (
        <article>
          <Loader />
        </article>
      )
    }

    if (listing.error) return <ErrorPage error={listing.error} />

    const {
      authFailed,
      office,
      members,
      brokers,
      rentalFormUrl,
      baseUrl,
      user,
      informationMemorandum,
      tenancyTrackerSubdomain,
      oneFormId,
      snugTeamId,
      tenappId,
      tenappEndpoint,
      tenancyPortalCode,
      rentiAgencyCode,
      irAccount,
      irRent,
      irSell,
      useViewingTracker,
      _window,
      suburb,
      language,
      dispatch,
      showOfficeDetails,
      showLoanMarketBroker,
      linkOfficeDetails,
      cdn,
      beforeYouBidId,
      showLoanMarketCalculator,
      calculatorPrice,
      organisationIds,
      displayOrganisationIds,
      staticMap,
      enableRentalForms,
    } = this.props

    const {
      showMapModal,
      showEnquiryModal,
      showSignInModal,
      showInspectionModal,
      showContractModal,
      enquiryEventLabel,
      media: galleryMedia,
      index: galleryIndex,
      showJumpLinks,
    } = this.state

    const scrollAnchor = scrollToId(_window, {
      getOffset: (e) => {
        const { currentTarget: { ownerDocument: document } = {} } = e
        if (!document) return 0
        const header = document.querySelector('.pdp_slidein_jump_links')
        if (!header) return 0
        // Scroll offset by the header and a little more for padding
        return -(header.getBoundingClientRect().height + 5)
      },
    })

    const setLanguage = lang => dispatch(patchUserData({ language: lang }))

    return (
      <ListingPage
        authFailed={authFailed}
        listing={listing}
        brokers={brokers.filter(broker => broker.loaded)}
        beforeYouBidId={beforeYouBidId}
        showLoanMarketCalculator={showLoanMarketCalculator}
        calculatorPrice={calculatorPrice}
        organisationIds={organisationIds}
        displayOrganisationIds={displayOrganisationIds}
        members={members}
        office={office}
        suburb={suburb}
        language={language}
        scrollAnchor={scrollAnchor}
        setLanguage={setLanguage}
        rentalFormUrl={rentalFormUrl}
        oneFormId={oneFormId}
        snugTeamId={snugTeamId}
        tenappId={tenappId}
        tenappEndpoint={tenappEndpoint}
        tenancyTrackerSubdomain={tenancyTrackerSubdomain}
        tenancyPortalCode={tenancyPortalCode}
        rentiAgencyCode={rentiAgencyCode}
        irAccount={irAccount}
        irRent={irRent}
        irSell={irSell}
        user={user}
        informationMemorandum={informationMemorandum}
        baseUrl={baseUrl}
        useViewingTracker={useViewingTracker}
        showMapModal={showMapModal}
        showEnquiryModal={showEnquiryModal}
        showInspectionModal={showInspectionModal}
        showSignInModal={showSignInModal}
        showContractModal={showContractModal}
        showLoanMarketBroker={showLoanMarketBroker}
        enquiryEventLabel={enquiryEventLabel}
        galleryMedia={galleryMedia}
        galleryIndex={galleryIndex}
        showJumpLinks={showJumpLinks}
        toggleEnquiryModal={this.toggleEnquiryModal}
        toggleAgentsEnquiryModal={this.toggleAgentsEnquiryModal}
        toggleMapModal={this.toggleMapModal}
        toggleSignInModal={this.toggleSignInModal}
        sendForm={this.sendForm}
        toggleInspectionModal={this.toggleInspectionModal}
        toggleContractModal={this.toggleContractModal}
        setJumpLinkPoint={this.setJumpLinkPoint}
        toggleGalleryModal={this.toggleGalleryModal}
        showOfficeDetails={showOfficeDetails}
        linkOfficeDetails={linkOfficeDetails}
        cdn={cdn}
        staticMap={staticMap}
        enableRentalForms={enableRentalForms}
        onGalleryNavigation={this.maybeTrackGallery}
      />
    )
  }
}

// Define data requirements for server side rendering
ListingPageContainer.fetchData = (dispatch, { listingId }, params) => {
  const {
    options,
    getListings,
    getMembers,
    getOrganisations,
    organisationIds,
    getState,
    response,
  } = params
  // Ensure valid ID given
  const _listingId = /^\d+$/.test(listingId) && parseInt(listingId, 10)
  if (!_listingId) {
    const error = new Error('Invalid listing ID given.')
    error.code = 404
    error.__abort = true
    error.__ignore = true
    return Promise.reject(error)
  }

  return unlazyAll([
    bits32(_listingId)
      ? dispatch(fetchListing(getListings, _listingId))
      : undefined,
    Promise.resolve(getOrganisations && organisationIds.length && (
      dispatch(loadOrganisations(getOrganisations, organisationIds)))
    ),
  ]).then(([listing]) => {
    if (listing.notFound || listing.error) return undefined

    // Members may be needed to know whether the listing is showable
    if (!options || options.agentShowTransactions !== 'any') return undefined
    if (listing.statusCode === 'CUR') return undefined
    if (~organisationIds.indexOf(listing.office.organisationId)) return undefined

    // Agents need to be loaded in order to know whether to show this listing
    return dispatch(loadMembers(getMembers, organisationIds))
  }).then(() => {
    // Only runs server side
    if (!response) return

    const { listings, members } = getState()
    const listing = getListing(listings, _listingId)
    if (listing.notFound) {
      response.status(404)
      return
    }

    if (listing.error) {
      response.status(500)
      return
    }

    const allMembers = getOrganisationMembers(members, organisationIds)

    // Confirm that the listing may be shown on this site
    if (!mayShowListing(
      listing.statusCode,
      listing.office.organisationId,
      listing.agents.map(({ memberId }) => memberId),
      organisationIds,
      allMembers.entities.map(({ memberId }) => memberId),
      options.agentShowTransactions === 'any',
    )) {
      response.status(404)
    }
  })
}

const median = values => values.sort((a, b) => a - b)[Math.floor(values.length / 2)]

const getCalculatorPrice = (listing, meta) => {
  try {
    const given = extractPrice(listing.displayPrice)
    if (given) return given

    const { statusCode, typeCode, subTypeCode } = listing
    const typeKey = [typeCode, subTypeCode].join('')
    const prices = (
      meta.typeKey[typeKey]
      && meta.typeKey[typeKey].statusCode[statusCode]
      && meta.typeKey[typeKey].statusCode[statusCode].prices
    )

    if (!prices) return undefined

    const extracted = prices
      .filter(p => p.total)
      .map(({ price, total }) => [...new Array(total)].map(() => price))
      .flat()
    return median(extracted)
  } catch (error) {
    // deliberately blank
  }
  return undefined
}

function mapStateToProps(state, props) {
  const listingId = parseInt(props.params.listingId, 10)
  let listing = getListing(state.listings, listingId)

  const members = (listing.agents || []) // Note: listing may not be loaded yet
    .filter(member => !!member.memberId)
    .map(agent => getMember(state.members, agent.memberId))

  const office = listing.loaded && listing.office ? getOrganisation(
    state.orgs, listing.office.organisationId
  ) : undefined
  const officeLoaded = office && office.loaded
  const meta = getListingsMetaFromStore(state.listings, [listingId])[listingId]

  const brokers = getBrokers(state.brokers, state.config.options.brokers.ids)

  if (listing.loaded && !listing.error && !listing.notFound) {
    const allMembers = getOrganisationMembers(state.members, state.config.organisationIds)

    // Confirm that the listing may be shown on this site
    if (!mayShowListing(
      listing.statusCode,
      listing.office.organisationId,
      listing.agents.map(({ memberId }) => memberId),
      state.config.organisationIds,
      allMembers.entities.map(({ memberId }) => memberId),
      state.config.options.agentShowTransactions === 'any',
    )) {
      listing = {
        ...listing,
        loaded: listing.loaded && allMembers.loaded,
        loading: listing.loading || allMembers.loading,
        notFound: listing.loaded && allMembers.loaded,
      }
    }
  }

  const calculatorPrice = listing.loaded && state.meta.site.loaded
    ? getCalculatorPrice(listing, state.meta.site)
    : undefined

  const licenceCode = officeLoaded && office.address && getOfficeLicenceCode(
    office.address.countryCode,
    office.address.stateCode,
    office.licenceCode
  )

  const linkOfficeDetails = officeLoaded && (
    state.config.options.showHiddenOffices
    || state.config.displayOrganisationIds.indexOf(office.organisationId) !== -1
  )

  return {
    listing,
    members,
    office,
    calculatorPrice,
    staticMap: !state.app.renderMap,
    brokers: brokers.entities.filter(broker => !broker.notFound),
    beforeYouBidId: state.config.options.beforeYouBidId,
    organisationIds: state.config.organisationIds,
    displayOrganisationIds: state.config.displayOrganisationIds,
    showOfficeDetails: shouldShowOfficeDetails(licenceCode, office, state.config),
    linkOfficeDetails: !!linkOfficeDetails,
    rentalFormUrl: state.config.options.rentalFormUrl,
    baseUrl: state.config.baseUrl,
    informationMemorandum: meta.informationMemorandum,
    tenancyTrackerSubdomain: state.config.options.tenancyTrackerSubdomain,
    oneFormId: state.config.options.oneFormId,
    snugTeamId: state.config.options.snugTeamId,
    tenappId: state.config.options.tenappId,
    tenappEndpoint: state.config.tenapp.endpoint,
    tenancyPortalCode: state.config.options.tenancyPortalCode,
    rentiAgencyCode: state.config.options.rentiAgencyCode,
    irAccount: state.config.options.inspectRealEstate.account,
    irRent: state.config.options.inspectRealEstate.rent,
    irSell: state.config.options.inspectRealEstate.sell,
    useViewingTracker: state.config.options.useViewingTracker,
    suburb: listing.loaded && listing.address
      ? getSuburb(state.suburbs, listing.address.suburbId)
      : undefined,
    showLoanMarketBroker: state.config.options.brokers.showOnPdp,
    showLoanMarketCalculator: state.config.options.showLoanMarketCalculator,
    language: props.location.query.language || getUserData(state.user).language || 'en',
    cdn: state.config.env.cdn,
    enableRentalForms: state.config.options.enableRentalForms,
  }
}

const context = withContext(
  'getOrganisations',
  'getMembers',
  'getBrokers',
  'fetch',
  '_window',
  'getListings',
  'getListingsMeta',
  'getSuburbs',
)

export default compose(
  // Load user, but do not require it, and render without waiting for data
  withUser({ required: false, deferred: false }),
  connect(mapStateToProps),
  context,
  withRouter,
)(ListingPageContainer)
